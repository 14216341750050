import React from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
  return (
    <section className="how-it-works" id="how-it-works">
      <div className="container">
        <h2 className="heading">
          How It <span>Works</span>
        </h2>

        <div className="steps-grid">
          {/* Step 1 */}
          <div className="step-box">
            <div className="step-number">1</div>
            <h3>Choose Your Service</h3>
            <p>
              Select from Pickup/Drop Service, Ride Sharing, or Vehicle Rental to get started on your journey.
            </p>
          </div>

          {/* Step 2 */}
          <div className="step-box">
            <div className="step-number">2</div>
            <h3>Enter Your Details</h3>
            <p>
              Fill in your trip details, including pick-up and drop-off locations, time, and vehicle preferences.
            </p>
          </div>

          {/* Step 3 */}
          <div className="step-box">
            <div className="step-number">3</div>
            <h3>Confirm & Pay</h3>
            <p>
              Review the details of your selected service and complete your booking with a secure payment process.
            </p>
          </div>

          {/* Step 4 */}
          <div className="step-box">
            <div className="step-number">4</div>
            <h3>Enjoy the Ride</h3>
            <p>
              Relax and enjoy a seamless and comfortable ride as we take care of everything for you.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
