import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="300" height="100" viewBox="0 0 124 124" fill="none">
            <g id="car_icon" transform='translate(0,20)'>
              <rect x="0" y="20" width="100" height="40" rx="10" ry="10" fill="#4B70F5" />
              <rect x="20" y="10" width="60" height="20" rx="5" ry="5" fill="#402E7A" />
              <circle cx="25" cy="65" r="10" fill="white" />
              <circle cx="75" cy="65" r="10" fill="white" />
            </g>
            <text x="120" y="65" font-family="Lato, sans-serif" font-weight="700" font-size="25" fill="#ffffff">
              S-Trip
            </text>
          </svg>
            <p>Riding You Towards the Future</p>
          </div>
          <div className="footer-links">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="#about-us">About Us</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#how-it-works">How It Works</a></li>
              <li><a href="#contact-us">Contact Us</a></li>
            </ul>
          </div>
          <div className="footer-contact">
            <h3>Contact Us</h3>
            <p>Email: support@strip.com</p>
            <p>Phone: +91 90752 97681</p>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} S-Trip. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
