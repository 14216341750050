import React from 'react';
import './Header.css'; // External CSS for styling

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        {/* Logo */}
        <div className="logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="133 0 14 140" width="150" height="50">
            {/* Car Icon */}
            <g id="car_icon" transform="translate(10, 30)">
              <rect x="0" y="20" width="100" height="40" rx="10" ry="10" fill="#4B70F5" />
              <rect x="20" y="10" width="60" height="20" rx="5" ry="5" fill="#402E7A" />
              <circle cx="25" cy="65" r="10" fill="white" />
              <circle cx="75" cy="65" r="10" fill="white" />
            </g>
            <text x="130" y="80" fontFamily="Lato, sans-serif" fontWeight="700" fontSize="36" fill="#ffffff">
              S-Trip
            </text>
            
          </svg>
        </div>

        {/* Navigation */}
        <nav className="nav">
          <ul className="nav-list">
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#features">Features</a></li>
            <li><a href="#contact-us">Contact</a></li>
          </ul>
        </nav>

        {/* Call to Action */}
        <div className="cta">
          <a href="#services" className="btn">Book Now</a>
        </div>
      </div>
    </header>
  );
};

export default Header;
