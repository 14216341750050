import React from 'react';
import './Features.css'; // External CSS for styling

const Features = () => {
  return (
    <section className="features" id="features">
      <div className="container">
        <h2 className="heading">
          Key <span>Features</span>
        </h2>
        <div className="features-grid">
          {/* Feature 1 */}
          <div className="feature-box">
            <i className="fas fa-user-friends feature-icon"></i>
            <h3>User-Friendly</h3>
            <p>
              Our platform is designed with a focus on simplicity and ease of use, providing an intuitive experience for everyone.
            </p>
          </div>

          {/* Feature 2 */}
          <div className="feature-box">
            <i className="fas fa-shield-alt feature-icon"></i>
            <h3>Secure & Reliable</h3>
            <p>
              We ensure a safe, secure, and reliable service for all users, with strong data protection and privacy measures in place.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="feature-box">
            <i className="fas fa-leaf feature-icon"></i>
            <h3>Eco-Friendly</h3>
            <p>
              Ride-sharing helps reduce your carbon footprint, making our platform a greener, more sustainable choice for transportation.
            </p>
          </div>

          {/* Feature 4 */}
          <div className="feature-box">
            <i className="fas fa-mobile-alt feature-icon"></i>
            <h3>Mobile-Friendly</h3>
            <p>
              Access our services seamlessly on mobile devices with our responsive design, allowing you to book trips on the go.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
