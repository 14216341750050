import React from 'react';
import './AboutUs.css'; // External CSS for styling

const AboutUs = () => {
  return (
    <section className="home" id="home">
      <div className="container">
        
        <p className="about-description">
          S-Trip is a dynamic trip-sharing platform that aims to revolutionize the way you travel.
          Whether it's a pickup-drop service, ride-sharing, or vehicle rental, S-Trip offers a seamless experience for all your transportation needs. 
          Ride with us towards a future of smart, sustainable, and shared transportation.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
