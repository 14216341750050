import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <section className="contact-us" id="contact-us">
      <div className="container">
        <h2 className="heading">
          Contact <span>Us</span>
        </h2>
        <p className="subheading">
          Have questions or want to reach out? We'd love to hear from you!
        </p>

        <div className="contact-content">
          {/* Contact Information */}
          <div className="contact-info">
            <h3>Get In Touch</h3>
            <p><i className="fas fa-phone"></i> Phone: +91 90752 97681</p>
            <p><i className="fas fa-envelope"></i> Email: support@s-trip.com</p>
            <p><i className="fas fa-map-marker-alt"></i> Address: Yuvraj Singh, Vadodara</p>
          </div>

          {/* Google Map */}
          <div className="google-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.0000000000005!2d73.12345678901234!3d22.12345678901234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc1234567890%3A0x1234567890abcdef!2sVadodara%2C%20Gujarat%2C%20India!5e0!3m2!1sen!2sus!4v1234567890123"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Vadodara Map"
            ></iframe>
          </div>

          {/* Contact Form */}
          <div className="contact-form">
            <h3>Send Us A Message</h3>
            <form>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" placeholder="Your Name" required />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" placeholder="Your Email" required />
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" placeholder="Your Message" required></textarea>
              </div>

              <button type="submit" className="btn-submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
