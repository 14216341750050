import React from 'react';
import Header from './components/Header'
import AboutUs from './components/AboutUs';
import Services from './components/Services'; 
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header /> 
      <AboutUs />
      <Services />
      <Features />
      <HowItWorks />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
