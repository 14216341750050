import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete, DirectionsRenderer } from '@react-google-maps/api';
import './Services.css';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const defaultCenter = {
  lat: 22.3072,
  lng: 73.1812,
};

const vehicleNames = [
  'Honda Activa', 'TVS Jupiter', 'Bajaj Pulsar', 'Royal Enfield', 'Hero Splendor',
  'Maruti Suzuki Swift', 'Hyundai i20', 'Tata Nexon', 'Mahindra Thar', 'Kia Seltos',
  'Toyota Fortuner', 'Honda City', 'Hyundai Creta', 'Maruti Suzuki Baleno', 'Ford EcoSport'
];

const getRandomVehicleName = () => {
  return vehicleNames[Math.floor(Math.random() * vehicleNames.length)];
};

const Services = () => {
  const [serviceType, setServiceType] = useState(null);
  const [pickupLocation, setPickupLocation] = useState('');
  const [dropLocation, setDropLocation] = useState('');
  const [currentLocation, setCurrentLocation] = useState(defaultCenter);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [directions, setDirections] = useState(null);
  const [totalDistance, setTotalDistance] = useState(null);
  const [totalFare, setTotalFare] = useState(null);
  const [randomPassenger, setRandomPassenger] = useState(null);
  const [randomVehicles, setRandomVehicles] = useState([]);

  const [pickupAutocomplete, setPickupAutocomplete] = useState(null);
  const [dropAutocomplete, setDropAutocomplete] = useState(null);

  useEffect(() => {
    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = { lat: latitude, lng: longitude };
          setCurrentLocation(userLocation);
          setMapCenter(userLocation);
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    }
  }, []);

  // Handle place changed for pickup
  const handlePickupPlaceChanged = () => {
    if (pickupAutocomplete !== null) {
      const place = pickupAutocomplete.getPlace();
      if (place.geometry) {
        setPickupLocation(place.formatted_address);
        setCurrentLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    }
  };

  // Handle place changed for drop
  const handleDropPlaceChanged = () => {
    if (dropAutocomplete !== null) {
      const place = dropAutocomplete.getPlace();
      if (place.geometry) {
        setDropLocation(place.formatted_address);
      }
    }
  };

  const handleUpdateMap = () => {
    if (pickupAutocomplete && dropAutocomplete) {
      const pickupPlace = pickupAutocomplete.getPlace();
      const dropPlace = dropAutocomplete.getPlace();

      if (pickupPlace.geometry && dropPlace.geometry) {
        let waypoints = [];

        if (serviceType === 'ride-sharing') {
          // Generate random passenger location within 10 km radius for ride-sharing
          const randomPassengerLocation = generateRandomLocation(
            pickupPlace.geometry.location.lat(),
            pickupPlace.geometry.location.lng(),
            10,
            1
          )[0]; // Get the first (and only) random location
          setRandomPassenger(randomPassengerLocation);

          waypoints.push({
            location: new window.google.maps.LatLng(randomPassengerLocation.lat, randomPassengerLocation.lng),
            stopover: true,
          });
        }

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: {
              lat: pickupPlace.geometry.location.lat(),
              lng: pickupPlace.geometry.location.lng(),
            },
            destination: {
              lat: dropPlace.geometry.location.lat(),
              lng: dropPlace.geometry.location.lng(),
            },
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
              setMapCenter({
                lat: pickupPlace.geometry.location.lat(),
                lng: pickupPlace.geometry.location.lng(),
              });

              // Calculate total distance and fare
              const distanceInMeters = result.routes[0].legs.reduce((acc, leg) => acc + leg.distance.value, 0);
              const distanceInKm = distanceInMeters / 1000;
              const farePerKm = 10; // Dummy price per km in INR
              const totalFare = distanceInKm * farePerKm;

              setTotalDistance(distanceInKm.toFixed(2));
              setTotalFare(totalFare.toFixed(2));
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
      }
    }
  };

  const generateRandomLocation = (lat, lng, radiusInKm, count = 1) => {
    const radiusInM = radiusInKm * 1000;
    const y0 = lat;
    const x0 = lng;
    const rd = radiusInM / 111300; // Convert radius from meters to degrees

    const locations = [];
    for (let i = 0; i < count; i++) {
      const u = Math.random();
      const v = Math.random();

      const w = rd * Math.sqrt(u);
      const t = 2 * Math.PI * v;
      const x = w * Math.cos(t);
      const y = w * Math.sin(t);

      const newLat = y + y0;
      const newLng = x + x0;

      locations.push({ lat: newLat, lng: newLng, name: getRandomVehicleName() });
    }

    return locations;
  };

  useEffect(() => {
    if (serviceType === 'vehicle-rental') {
      const vehicles = generateRandomLocation(currentLocation.lat, currentLocation.lng, 10, Math.floor(Math.random() * 11) + 5);
      setRandomVehicles(vehicles);
    }
  }, [serviceType, currentLocation]);

  const renderMap = useCallback(() => {
    if (serviceType === 'pickup-drop' || serviceType === 'vehicle-rental' || serviceType === 'ride-sharing') {
      return (
        <GoogleMap mapContainerStyle={mapContainerStyle} zoom={13} center={mapCenter}>
          <Marker position={mapCenter} />
          {directions && <DirectionsRenderer directions={directions} />}
          {serviceType === 'ride-sharing' && randomPassenger && <Marker position={randomPassenger} />}
          {serviceType === 'vehicle-rental' && randomVehicles.map((vehicle, index) => (
            <Marker key={index} position={{ lat: vehicle.lat, lng: vehicle.lng }} label={vehicle.name} />
          ))}
        </GoogleMap>
      );
    }
    return null;
  }, [serviceType, mapCenter, directions, randomPassenger, randomVehicles]);

  return (
    <div className='services' id='services'>
      <h2 className='heading'>Service <span> Selection</span> </h2>

      {/* Service selection buttons */}
      <div>
        <button onClick={() => { setServiceType('pickup-drop'); window.scrollBy(0, 200); }}>Pickup Drop Service</button>
        <button onClick={() => { setServiceType('ride-sharing'); window.scrollBy(0, 200); }}>Ride Sharing</button>
        <button onClick={() => { setServiceType('vehicle-rental'); window.scrollBy(0, 200); }}>Vehicle Rental</button>
      </div>

      {/* Pickup Drop Service */}
      {serviceType === 'pickup-drop' && (
        <div>
          <h2>Pickup Drop Service</h2>
          <label>Pickup Location:</label>
          <Autocomplete
            onLoad={(autocomplete) => setPickupAutocomplete(autocomplete)}
            onPlaceChanged={handlePickupPlaceChanged}
          >
            <input
              type="text"
              value={pickupLocation}
              onChange={(e) => setPickupLocation(e.target.value)}
              placeholder="Enter pickup location"
            />
          </Autocomplete>

          <label>Drop Location:</label>
          <Autocomplete
            onLoad={(autocomplete) => setDropAutocomplete(autocomplete)}
            onPlaceChanged={handleDropPlaceChanged}
          >
            <input
              type="text"
              value={dropLocation}
              onChange={(e) => setDropLocation(e.target.value)}
              placeholder="Enter drop location"
            />
          </Autocomplete>

          {/* Button to update the map */}
          <button onClick={handleUpdateMap} className='input-button-container'>Calculate Fare</button>

          {renderMap()}

          {/* Display total distance and fare */}
          {totalDistance && totalFare && (
            <div>
              <p>Total Distance: {totalDistance} km</p>
              <p>Total Fare: ₹{totalFare}</p>
            </div>
          )}
        </div>
      )}

      {/* Ride Sharing */}
      {serviceType === 'ride-sharing' && (
        <div>
          <h2>Ride Sharing</h2>
          <label>Pickup Location:</label>
          <Autocomplete
            onLoad={(autocomplete) => setPickupAutocomplete(autocomplete)}
            onPlaceChanged={handlePickupPlaceChanged}
          >
            <input
              type="text"
              value={pickupLocation}
              onChange={(e) => setPickupLocation(e.target.value)}
              placeholder="Enter pickup location"
            />
          </Autocomplete>

          <label>Drop Location:</label>
          <Autocomplete
            onLoad={(autocomplete) => setDropAutocomplete(autocomplete)}
            onPlaceChanged={handleDropPlaceChanged}
          >
            <input
              type="text"
              value={dropLocation}
              onChange={(e) => setDropLocation(e.target.value)}
              placeholder="Enter drop location"
            />
          </Autocomplete>

          {/* Button to update the map */}
          <button onClick={handleUpdateMap} className='input-button-container'>Calculate Fare</button>

          {renderMap()}

          {/* Display total distance and fare */}
          {totalDistance && totalFare && (
            <div>
              <p>Total Distance: {totalDistance} km</p>
              <p>Total Fare: ₹{totalFare}</p>
            </div>
          )}
        </div>
      )}

      {/* Vehicle Rental */}
      {serviceType === 'vehicle-rental' && (
        <div>
          <h2>Vehicle Rental</h2>
          <label>Current Location:</label>
          <input
            type="text"
            value={`Lat: ${currentLocation.lat}, Lng: ${currentLocation.lng}`}
            readOnly
          />
          {renderMap()}
        </div>
      )}
    </div>
  );
};

const App = () => (
  <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['places']}>
    <Services />
  </LoadScript>
);

export default App;
